.gov__link {
  color: #8dde43;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 0.15px;
  cursor: pointer;
  display: flex;
}

.gov__link span:hover {
  border-bottom: 2px solid $greenPrimary;
}

.gov__chevron {
  background-image: url('../../assets/chevron.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 23px;
  height: 15px;
  cursor: pointer;

  &.right {
    transform: rotate(90deg);
  }

  &.left {
    transform: rotate(270deg);
  }

  &.down {
    transform: rotate(180deg);
  }
}

.margin-left {
  margin-left: 0.8rem;
}

.button {
  border-radius: 6px;
  background-color: $greenPrimary;
  border-style: solid;
  border-color: transparent;
  font-weight: $fontWeightExtraBold;
  font-size: 18px;
  cursor: pointer;

  &:disabled {
    background-color: $grey;
    border-color: $grey;
    color: #787878;
    cursor: not-allowed;
  }
}

.button1 {
  background-color: white;
  color: black;
}

.button-reject {
  padding: 10px;
  background-color: #f44336;
  color: black;
}

.button-accept {
  margin-right: 1.2rem;
  padding: 10px;
  background-color: $greenPrimary;
  color: black;
}



