.gov__incorrect-information-container {
    margin: 30px; // center the content of the container div
    width: 80%; // sets a width on the sides
    height: 92%;

    .gov__btn {
        .gov__arrow-right {
            transform: rotate(180deg) !important;
            margin-bottom: 0;
        }
    }

    .gov__incorrect-information-subtitle {
        color: $greenPrimary;
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.03px;
        font-size: 20px;
        line-height: 24px;
        // opacity: 0;
        // transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
    }

    .gov__incorrect-information-select-option {
        color: $black;
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.03px;
        font-size: 17.5px;
        line-height: 24px;
        // opacity: 0;
    }

    .gov__incorrect-information-options {
        margin-bottom: 10px;
        .gov__gov__incorrect-information-option {
            margin-bottom: inherit;
            overflow: hidden;
            .gov__checkbox-container {
                margin-bottom: inherit;
                float:left;
            }

            .gov__option-text {
                margin-bottom: inherit;
                overflow: hidden;
            }
        }
    }

    p {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -1.07px;
    }

    div {
        margin-bottom: 25px;
    }
}

// Tablet adjustments
@media (min-width: 768px) {
    .gov__incorrect-information-container {
        width: auto;
    }
}

.gov__checkbox-label {
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;

    // Hide the browser's default checkbox
    input {
        display: none;

        // When the checkbox is checked, add a blue background
        &:checked ~ .gov__checkmark {
            // Show the checkmark when checked
            &:after {
                display: block;
            }
        }
    }

    // Style the checkmark
    .gov__checkmark:after {
        background: url('../../assets/tick.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 25px;
        width: 30px;
    }
}

// The container holding the checkmark
.gov__checkmark {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fefefe;
    border: 1px solid #979797;
    opacity: 0.49; border-radius: 
    2px; background-color: #FFFFFF; 
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.19);

    // Create the checkmark/indicator (hidden when not checked)
    &:after {
        content: "";
    }
}

.gov__contact-form {
    margin: 30px;
    h1 {
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.16px;
        font-size: 16px;
        line-height: 20px;
        color: $greenPrimary;
    }

    .gov__contact-form-subtext {
        color: #202020;
        font-weight: 800;
        letter-spacing: 0.03px;
        font-size: 17.5px;
        line-height: 24px;
    }
}

.gov__textarea {
    font-weight: bold;
    resize: none;
    border: 2px solid #e3e3e3;
    min-height: 230px;
    width: 95%;
    font-size: 17px;
    padding: 1rem;
    border-radius: 3px;
    transition: border ease 0.5s;
    -webkit-appearance: none;

    &:focus {
        outline: none;
        border: 2px solid $greenPrimary;
    }
}

.gov__character-count {
    font-size: small;
    position: absolute;
    margin-top: -40px;
    margin-left: 1rem;

    // stop text overlapping character count
    background: white;
    width: 70%;
}
.gov__text {
    font-weight: $fontWeightExtraBold;
    line-height: 22px;
    letter-spacing: 0.03px;
    &.md {
        font-size: 3vh;
        line-height: 4vh;
        letter-spacing: -1.07px;
    }
    
    &.xs {
        font-size: 2vh;
        line-height: 18px;
    }

    &.xxs {
        font-size: 1.5vh;
        line-height: 16px;
    }

    // Font Weight
    &.light {
        font-weight: $fontWeightLight;
        letter-spacing: 0.02px;
    }

    // Font Colour
    &.green {
        color: $greenPrimary;
    }

    &.grey-regular {
        color: $grey;
    }

    &.grey {
        color: $greyDark;
    }

    &.red {
        color: $red;
    }
}

