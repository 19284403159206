.gov__candidate-search {
    height: 65px;
    font-size: 22px;
    line-height: 56px;
    letter-spacing: -1.07px;
    font-weight: $fontWeightExtraBold;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.08);
    padding: 0 5px;
    background: url('../../assets/search.svg') no-repeat scroll 0px 8px;
    padding-left: 50px;
    margin-top: 25px;
    margin-bottom: 15px;
    box-sizing: border-box;
    // opacity: 0;
    // transform: translateY(200%);
    // animation: slideInUp 1s forwards;
    display: block;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;

    &::placeholder {
        color: #767676;
        font-size: 22px;
    }
  
    &:focus {
        outline: 0;

        &::placeholder {
            color: transparent;
        }
    }

    &.error {
        border-bottom: 3px solid $red;
    }
}

// @media (min-width: 768px) {
//     .gov__chevron {    
//         &.help-section {
//             width: 23px;
//             height: 15px;
//             margin-top: 6px;
//             margin-left: 6px;
//         }
//     }
// }
