.infobar {
    width: auto;
    background-color: transparent;
    overflow: auto;
    display: block;
    float: right;
  }
  
  .infobar a {
    float: left;
    padding: 12px;
    color: black;
    text-decoration: none;
    font-size: 17px;
  }
  
//   @media screen and (max-width: 500px) {
//     .navbar a {
//       float: none;
//       display: inline-block;
//     }
// }