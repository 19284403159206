.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 800px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);

  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header {
  //background-color: #E8E8E8;
  color: white !important;
  text-align: center;
  font-weight: $fontWeightExtraBold;
  font-size: 30px;
  padding: 25px;

  margin: -11px;
  margin-top: -13px;
  margin-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  p {
    font-size: 1.5rem;
    color: #282c34;
    padding: 0;
    margin: 0;
  }
}

.modal-footer {
  padding: 10px;
  //background-color: #f9f9f9;
  margin: -10px;
  margin-top: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.modal-title {
  margin: 0;
  position: absolute;
  top: 17px;
  left: 42%;

  // position: relative;
  // display: flex;
  // justify-content: center;
  // top:-40px
}

.modal-body {
  padding: 20px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 8px;
}

.button-center {
  display: flex;
  justify-content: center;
}

.close {
  position: absolute;
  right: 12px;
  top: 20px;
  width: 32px;
  height: 32px;
  opacity: 1;
}

.close:hover {
  opacity: 1.2;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;

}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}
  
  