.gov__candidate-combination {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
  margin: 0 0 2rem 0;

   //opacity: 0;
   //transform: translateY(200%);
   //animation: slideInUp 1s forwards;
   //animation-delay: 0.75s;
}

.gov__candidate-combination:hover {
  background: rgba(green, 0.25);
  cursor: pointer;
  
}

.gov__candidate-combination-title {
  font-size: 14px;
  letter-spacing: 0.11px;
  line-height: 16px;
  font-weight: $fontWeightExtraBold;
  margin: 0;
}

.gov__candidate-combination-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: $fontWeightLight;
  letter-spacing: -0.32px;
  line-height: 24px;
}

.gov__candidate-combination-section {
  margin-right: 1rem;
}