.gov__document {
    text-align: center;

    .doc__point {
        list-style-position: inside;
    }
}
.positionBackBtn {
    position: absolute;
    top: 17px;
    left: 15px;
}