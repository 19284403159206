.flexgrid {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 65rem) {
    margin: -1rem;
  }

  > div {
    flex-basis: 100%;
    box-sizing: border-box;

    @media (min-width: 65rem) {
      box-sizing: border-box;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 0.8rem;
      flex-basis: calc(33.333% - 2rem);
    }
  }

  > div + div {
    box-sizing: border-box;
    @media (max-width: 65rem) {
      box-sizing: border-box;
      margin-top: 2rem;
    }
  }
}

.marginsList {
  margin-right: 20px;
  margin-left: 20px;
  display: inline-block;
}
  