.header {
    margin-top: 20px;
    height: 110px;
    display: flex;
    padding-bottom: 1rem;

    .left-col {
        float: left;
        width:25%;
    }
    .center-col {
        float: left;
        width: 50%;
        // padding-top: 10px;
    }
    .right-col {
        float: left;
        width: 25%;
    }

    .logo {
        height: 80px;
    }

    .gov__logo {
        font-size: 30px;

        span:nth-child(1) {
            font-weight: $fontWeightLight;
        }

        span:nth-child(1).gov__deloitte {
            font-weight: $fontWeightExtraBold;
        }

        span:nth-child(2) {
            color: $greenPrimary;
        }
    }
    
    .gov__language-nav {
        display: flex;
        align-items: center;
    
        span {
            font-size: 14px;
            margin-left: 3px;
        }
    }
}

